import React, { useEffect } from 'react';

const MarketOpportunity = () => {
    useEffect(() => {
        const scrollToNavDiv = async () => {
            const navDiv = document.getElementById('nav_main');
            if (navDiv) {
                await navDiv.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNavDiv();
    }, []);
    return (
        <div style={{ background: '#EEECE6', padding: "30px 15%" }}>
            <h2 className="upper_heading" style={{ fontSize: '36px', fontWeight: '500' }}>About Your Market</h2>
            <h3 className="upper_heading">Market Opportunity</h3>
            <p className="upper_description">The opportunity for this product does have a significant growth scale in the export market. As this product is primarily produced in the east which includes countries like India, China, Pakistan, and a few more. The market we will be focusing on also has a high import requirement for the henna leaf and its by-products. As we will be entering into this space for the first phase, we will only be focusing on the Henna leaf at its purest quality and the most basic stage in production.</p>

            <h3 className="upper_heading">Market Size</h3>
            <p className="upper_description">Segment your market into logical customer groupings.</p>

            <h3 className="upper_heading">Industry Trends</h3>
            <p className="upper_description">Industry trends that can help us succeed include the increasing demand for organic and natural products, growing awareness of the benefits of henna, and the rise of online retail channels for global distribution. However, potential inhibitors to our success could include fluctuations in raw material prices, regulatory changes impacting international trade, and competition from established players in the market.</p>

            <h3 className="upper_heading">Economic Climate Influence</h3>
            <p className="upper_description">Yes, the economic climate can influence our business. Factors such as currency exchange rates, trade policies, and overall economic stability in our target markets can impact consumer purchasing power and demand for our products.</p>

            <h3 className="upper_heading">Business or Market Affected By</h3>
            <ul>
                <li className="upper_description">Business Cycles: Yes, as economic conditions fluctuate, consumer spending habits may change, affecting our sales volume.</li>
                <li className="upper_description">Seasons: Seasonal variations may impact demand for henna products, with higher demand typically seen during festival seasons or wedding seasons in certain regions.</li>
            </ul>

            <h3 className="upper_heading">Pareto Principle</h3>
            <p className="upper_description">We plan to garner 80% of our revenues from the top 20% of our customer base, which may include large distributors, beauty salons, and online retailers.</p>
        </div>
    );
}

export default MarketOpportunity;
