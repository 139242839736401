import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Logo from '../../../Assets/LOGO(final).png'
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function TemporaryDrawer() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: '250px', background: 'rgb(64, 70, 44)', height: '100vh' }} role="presentation">
            <div style={{display:"flex",justifyContent:'center',alignItems:"center",padding:'30px',paddingBottom:'20px'}}>
                <img className='sidebar_logo' src={Logo} alt="" />
            </div>
            <List style={{ flexDirection: 'column', alignItems: 'center', gap: '15px', width: '100%' }}>
                {[{ name: 'WHAT WE DO', to: '/what-we-do' }, { name: 'OUR STORY', to: '/our-story' }, { name: 'PARTNERS', to: '/partners' }, { name: 'CONTACT', to: '/contact' }].map((text, index) => (
                    <ListItem key={text.name} disablePadding>
                        <ListItemButton style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Link onClick={() => setOpen(false)} style={{ marginLeft: '10px', width: '70%', borderRadius: "12px", border: '2px solid white', textAlign: 'center', padding: '5px 0', color: 'white' }} to={text.to}>{text.name}</Link>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            {/* <List style={{ flexDirection: 'column', alignItems: 'center' }}>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </Box>
    );
    return (
        <div>
            <Button onClick={toggleDrawer(true)}><FontAwesomeIcon icon={faBars} style={{ width: '30px', fontSize: "30px", color: 'rgb(206, 161, 101)' }} /></Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}