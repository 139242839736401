import React from 'react';
import './newsdialogue.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const NewsDialogue = ({ setFlag }) => {
    return (
        <div className='news_dialogue'>
            <div className="news_dialogue_main">
                <div className="news_dialogue_top">
                    <p className="news_dialogue_heading">Trade Information</p>
                    <FontAwesomeIcon icon={faXmark} style={{marginLeft:'auto' , fontSize:'25px',color:'black'}} onClick={()=>setFlag(false)} />
                </div>
                <div className="news_dialogue_inner_div"> 
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">FOB Port</p>
                        <p className="news_dialogue_inner_div_part2">All Port of India</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Payment Terms</p>
                        <p className="news_dialogue_inner_div_part2">Letter of Credit (L/C)<br />Telegraphic Transfer (T/T)<br />Cash Advance (CA)</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Supply Ability</p>
                        <p className="news_dialogue_inner_div_part2">1000 Per Day</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Delivery Time</p>
                        <p className="news_dialogue_inner_div_part2">7 Days</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Sample Available</p>
                        <p className="news_dialogue_inner_div_part2">Yes</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Sample Policy</p>
                        <p className="news_dialogue_inner_div_part2">Free samples available with shipping and taxes paid by the buyer</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Packaging Details</p>
                        <p className="news_dialogue_inner_div_part2">We have small and bulk packaging size available.<br />We also provide private labeling.</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Main Export Market(s)</p>
                        <p className="news_dialogue_inner_div_part2">Asia North America Eastern Europe Western Europe Africa</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Main Domestic Market</p>
                        <p className="news_dialogue_inner_div_part2">All India</p>
                    </div>
                    <div className="news_dialogue_inner_div_parts">
                        <p className="news_dialogue_inner_div_part1">Certifications</p>
                        <p className="news_dialogue_inner_div_part2">ISO GMP HALAL</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsDialogue;
