import React, { useEffect } from 'react'
import slide from '../../../Assets/home/polvo_manos.png'
import { gsap } from 'gsap'
const StoryBanner = () => {
    useEffect(() => {
        const handleMouseEnter = (classname = 'home_banner_text') => {
            gsap.to(`.${classname}`, {
                left: '10%', // Move the element to the left by its own width
                duration: 2,
                opacity: 1,
                ease: 'power2.inOut'
            });
        };

        handleMouseEnter();
    }, []);
    return (
        <div className='home_banner_main'>
            <div className="background_tint">
                <p className="home_banner_text" style={{ right: '-50%', position: 'absolute', opacity: '0', color: '#C99652' }}>
                    Welcome to LRT Exports - Home of Premium Henna
                </p>
            </div>
        </div>
    )
}

export default StoryBanner