import React, { useEffect } from 'react'
import gas from '../../../Assets/About/gas.png'
import camera from '../../../Assets/About/camera.png'
import img1 from '../../../Assets/story/story_1.png'
import img2 from '../../../Assets/story/story_2.png'
import img3 from '../../../Assets/story/story_3.png'
import img5 from '../../../Assets/story/story_5.png'
import img6 from '../../../Assets/story/story_6.png'
import customized_solutions from '../../../Assets/story/customized_solutions.png'

import { gsap } from 'gsap'
// gsap.registerPlugin(ScrollTrigger)
const StoryMain = () => {
    const complete = () => {
        const tl = gsap.timeline();
        tl.to('.story_heading', { duration: 3, width: '100%', ease: 'power1.inOut' }); // Typing animation

        return () => {
            tl.kill(); // Cleanup the animation when the component unmounts
        };
    }

    return (
        <div className='story_main_componentt' onMouseOver={complete} >
            <p className="story_heading story_heading" style={{ textAlign: 'center' }}>OUR STORY 
            </p>
            <p className="story_desc" style={{ marginTop: '40px', textAlign: 'start' }}>
                With over 40 years of experience in processing, packaging and sales of henna, indigo, and other organic cosmetic products in South India, we have established ourselves as a reputable player in the market. LRT Exports, a leading exporter of premium organic henna, is expanding its horizons now!
                <br />
                <br />
                We are excited and committed to offer the highest quality, ethically sourced henna, to introduce new product lines. to stay true to our values of sustainability and innovation and, to deliver with promise.
            </p>
            <div className="story_box">
                <div className="story_content">
                    <p className="story_heading">
                        Diverse Manufacturing & Commerce
                    </p>
                    <p className="story_desc">
                        Organic henna, with plans to introduce allied products such as organic bath powders, face creams, dental aligners, and electronic parts
                    </p>
                </div>
                <div className="story_img">
                    <img src={img1} alt="" />
                </div>
            </div>
            <div className="story_boxx">
                <div className="story_img">
                    <img src={img2} alt="" />
                </div>
                <div className="story_content">
                    <p className="story_heading">
                        Compliance and Guarantee
                    </p>
                    <p className="story_desc">
                        Adhering to trade certifications, licenses, and country-specific regulations, we guarantee the best quality products at competitive prices, supported by our partnership's long standing reliability.
                    </p>
                </div>
            </div>
            <div className="story_box">
                <div className="story_content">
                    <p className="story_heading">
                        Integrated Support & IT Services
                    </p>
                    <p className="story_desc">
                        Establish a suite of support services, including IT-enabled and conventional solutions, such as support centers, enterprise content management and data services, offering expert consultancy in these domains.
                    </p>
                </div>
                <div className="story_img">
                    <img src={img3} alt="" />
                </div>
            </div>
            <div className="story_boxx">
                <div className="story_img">
                    <img src={customized_solutions} alt="" />
                </div>
                <div className="story_content">
                    <p className="story_heading">
                        Customized Solutions
                    </p>
                    <p className="story_desc">
                        Offering tailored OEM and ODM services, we provide a means for global brands to take a step towards a greener tomorrow.
                    </p>
                </div>

            </div>
            <div className="story_boxx" style={{flexWrap:'wrap-reverse'}}>
                <div className="story_content">
                    <p className="story_heading">
                        Trusted Partners
                    </p>
                    <p className="story_desc">
                        Leveraging the expertise of our partner, Anoos Electrolysis and Obesity Pvt. Ltd., with over 40 years of experience in henna and indigo powder manufacturing and processing
                    </p>
                </div>
                <div className="story_img">
                    <img src={img5} alt="" />
                </div>
            </div>
            <div className="story_boxx" style={{marginBottom:"30px"}}>
                <div className="story_img">
                    <img src={img6} alt="" />
                </div>
                <div className="story_content">
                    <p className="story_heading">
                        Marketing Strategy Focus
                    </p>
                    <p className="story_desc">
                        While understanding customer needs is essential, our marketing strategy begins with highlighting the unique benefits and advantages of our products.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default StoryMain