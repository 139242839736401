import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger plugin
import { aboutContent } from '../../../Constants/data';
import '../../../App.css'; // You may need to create this CSS file for component styles
import { Link } from 'react-router-dom';
import useMousePosition from '../../hooks/useMousePosition';

const AboutMain = () => {
    const aboutMainRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const { x, y } = useMousePosition();
    const size = isHovered ? 40 : 40;

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger plugin

        // Animations for the children elements
        const handleMouseEnter = (e) => {
            gsap.to(e.currentTarget, {
                width: '100%',
                height: '700px',
                backgroundSize: '100% 700px',
                duration: 0.3,
            });
        };

        const handleMouseLeave = (e) => {
            gsap.to(e.currentTarget, {
                width: '48%',
                height: 'auto',
                duration: 0.3,
            });
        };

        gsap.utils.toArray('.about_main_inner_div').forEach((element) => {
            element.addEventListener('mouseenter', handleMouseEnter);
            element.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            gsap.utils.toArray('.about_main_inner_div').forEach((element) => {
                element.removeEventListener('mouseenter', handleMouseEnter);
                element.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    return (
        <div ref={aboutMainRef} className='about_main_component'>
            {
                aboutContent.map((e, index) => (
                    <Link to={e.url} key={index} className="about_main_inner_div" style={{ backgroundImage: `url(${e.img})` }}>
                        <p className="about_main_title">{e.title}</p>
                        {/* <p className="about_main_desc">{e.desc}</p> */}
                    </Link>
                ))
            }
        </div>
    );
};

export default AboutMain;
