import React, { useEffect, useRef, useState } from 'react';
import banner_video from '../../../Assets/video/banner_3_video.mp4';
import gsap from 'gsap';
import { Link } from 'react-router-dom';

const Banner3 = () => {
    const [flag, setFlag] = useState(false);
    const divRef = useRef(null);
    const divRef1 = useRef(null);

    // useEffect(() => {
    //     const f = (ref) => {
    //         const shakeDiv = ref.current;

    //         // Define the shaking animation
    //         const shakeTween = gsap.to(shakeDiv, {
    //             x: -5,
    //             duration: 0.1,
    //             repeat: 4,
    //             yoyo: true,
    //             onComplete: () => {
    //                 // Reset position after animation completes
    //                 gsap.set(shakeDiv, { x: 0 });
    //             }
    //         });

    //         // Clean up function to kill the animation when component unmounts
    //         return () => {
    //             shakeTween.kill();
    //         };
    //     }

    //     // Attach mouseover event listeners to each divRef
    //     divRef.current.addEventListener('mouseover', () => f(divRef));
    //     divRef1.current.addEventListener('mouseover', () => f(divRef1));
    // }, []); // Empty dependency array ensures this runs only once on mount

    return (
        <div className='banner3_main'>
            <div className="banner_first_half" ref={divRef}>
                <div className="background_tint">
                    <div className="banner3_inner_div">
                        <p className="banner3_inner_text">
                            CROP
                        </p>
                        <Link to={'/what-we-do'} style={{display:'flex',justifyContent:'center',alignItems:'center'}} className="banner_3_button">
                            Take A Look
                        </Link>
                    </div>
                </div>
            </div>
            <div className="banner_second_half" >
                <video style={{ width: '100%', height: '100%', objectFit: 'cover', zIndex: '' }} autoPlay loop muted={!flag} onClick={() => setFlag(!flag)}>
                    <source src={banner_video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="background_tint background_tint3" style={{ position: 'absolute', zIndex: "" ,height:'600px',width:'50%'}}>
                    <div className="banner3_inner_div" style={{ position: 'relative' }}>
                        <p className="banner3_inner_text">
                            PROCESSING FACILITY
                        </p>
                        <Link to={'/what-we-do'} style={{display:'flex',justifyContent:'center',alignItems:'center'}} className="banner_3_button">
                            EXPERIENCE IT
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner3;
