import about1 from '../Assets/About/conscious.png'
import about2 from '../Assets/About/concious.png'
import about3 from '../Assets/About/img2.png'
import about4 from '../Assets/About/born.png'
import cr1 from '../Assets/what/crop4 (1) 1.png'
import cr2 from '../Assets/what/package-2 1.png'
import cr3 from '../Assets/what/powdering1 1.png'
import cr4 from '../Assets/what/sifting1 1.png'
export const aboutContent = [
    {
        img: about1,
        title: 'OUR STORY',
        url:'/our-story',
        desc: 'In our current world where creativity is key and sustainability is paramount, transparency thrives.'
    },
    {
        img: about4,
        url:'/about-your-business',
        title: 'ABOUT YOUR BUSINESS',
        desc: 'Creating a better future with organic henna powder.'
    },
    {
        img: about3,
        title: 'IDEAL CUSTOMER',
        url:'/ideal-customer',
        desc: 'Always using the best technology for processing and proving the best quality. '
    },
    {
        img: about2,
        title: 'ABOUT YOUR MARKET',
        url:'/about-your-market',
        desc: 'Providing Henna powder to millions since 40 years domestically.'
    },
]

export const carouselImages=[
    cr1,cr4,cr3, cr2
]

export const carouselData=[
    'A yield from the organically grown farm is of highly sustainable farming methods.',
    'Our leaves are sent through a washing unit to ensure only the best are approved. Our precision techniques then ensure quality ground powder.',
    'With our specially designed sifts for superior quality, a manual check only speaks for our promise with the product.',
    'For quality inspection, we perform a Sieve Analysis Test. This is followed by mindtful packaging.'
]