import React, { useState } from 'react'
import news1 from '../../../Assets/home/MJC08255.jpg'
import news2 from '../../../Assets/home/DSC07293.jpg'
import { gsap } from 'gsap'
import NewsDialogue from '../../Dialogues/NewsDialogue'
const News = () => {
    const [flag,setFlag]=useState(false);
    const handleMouseEnter = (classname) => {
        gsap.to(`.${classname}`, {
            width: '140%', // Expanded width
            transformOrigin: 'left center', // Expand from left side
            duration: 0.5 // Duration of the animation
        });
    };

    const handleMouseLeave = (classname) => {
        gsap.to(`.${classname}`, {
            width: '100%', // Initial width
            transformOrigin: 'left center', // Shrink from left side
            duration: 2 // Duration of the animation
        });
    };

    return (
        <div className='home_news_main ' style={{ marginBottom: '60px' }}>
            <div className="home_news_main_inner">
                <p className="news_heading" style={{ color: "black" }}>NEWS</p>
                <div className='news_images ' >
                    <div className="home_news_first home_news_first1" >
                        <img src={news1} alt="" className='expandableDiv' />
                        <p className="home_news_text1">
                            LRT Exports X ANOO’S
                        </p> 
                        <p className="home_news_text2"style={{color:"white"}}>
                            LRT Exports has worked with authentic producers, together with Anoo’s Electrolysis and Obesity Pvt. Ltd. we will continue to provide this organic product globally.
                        </p>
                    </div>
                    <div className="home_news_first" onClick={()=>setFlag(true)}>
                        <img src={news2} alt="" /*onMouseOver={() => handleMouseEnter('expandableDiv1')}
                            onTouchStart={() => handleMouseEnter('expandableDiv1')}
                            onMouseLeave={() => handleMouseLeave('expandableDiv1')} */className='expandableDiv1' />
                        <p className="home_news_text1">
                            TRADE INFORMATION
                        </p>
                        <p className="home_news_text2" style={{color:'white',fontSize:'18px'}}>
                            CLICK HERE !!
                        </p>
                    </div>
                </div>
            </div>
            {flag&&<NewsDialogue setFlag={setFlag}/>}
        </div>
    )
}

export default News