import React, { useEffect } from 'react'
import './partners.css'
import { gsap } from 'gsap'
import banner from '../../Assets/home/polvo_manos.png'
import logo from '../../Assets/story/anoos_logo.png'
import { Link } from 'react-router-dom'
const Partners = () => {
    useEffect(() => {
        const handleMouseEnter = (classname = 'home_banner_text') => {
            gsap.to(`.${classname}`, {
                left: "10%", // Move the element to the left by its own width
                duration: 2,
                ease: 'power2.inOut'
            });
        };

        handleMouseEnter();
    }, []);
    useEffect(() => {
        const scrollToNavDiv = async () => {
            const navDiv = document.getElementById('nav_main');
            if (navDiv) {
                await navDiv.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNavDiv();
    }, []);
    return (
        <div className='partners_main_component'>
            <div className='home_banner_main' style={{ height: '650px', backgroundImage: `url('${banner}')` }}>
                <div className="background_tint">
                    <p className="home_banner_text" style={{ right: '-50%', position: 'absolute', color: '#C99652' }}>
                        Partner With Us
                    </p>
                </div>
            </div>
            <div className="partners_content">
                <p className="parteners_content_heading">
                    Anoo’s has been in the domestic market since 42 years in the organic cosmetic industry. They are producers of premium henna and other chemical free based products.
                </p>
                <Link to={'https://www.anoos.com/'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '8vh 0', textDecoration: "none",flexDirection:'column' }}>
                    <img src={logo} style={{ width: '50%', height: '150px' ,paddingBottom:'30px'}} alt="" />
                    <p className="partner_footer_title1">CLICK HERE FOR MORE INFORMATION</p>
                    <p className="partner_footer_title1">HYDERABAD, TELENGANA, INDIA, 500083</p>
                </Link>
            </div>
        </div>
    )
}

export default Partners