import React, { useEffect } from 'react';

const BusinessInfo = () => {
  useEffect(() => {
    const scrollToNavDiv = async () => {
      const navDiv = document.getElementById('nav_main');
      if (navDiv) {
        await navDiv.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToNavDiv();
  }, []);
  return (
    <div style={{ background: '#EEECE6', padding: "30px 15%" }}>
      <h2 className="upper_heading" style={{ fontSize: '36px', fontWeight: '500' }}>About Our Business</h2>
      <p className="upper_description">A1. LRT Exports Pvt. Ltd. Is currently working on the export of a single product that is organic henna and will venture into other products as well, in the future. The aim of LRT exports is to identify potential buyers in the market for purchasing henna in significant quantities and at a regular timeline. Our first goal is to identify two countries and begin understanding its use and create a growth pattern in consumption in order to identify valid buyers.</p>
      <p className="upper_description">My organization has been in the business of processing, packaging and sale of henna indigo and other organic cosmetic products in the south of India since the past 40 years.</p>
      <p className="upper_description">Our organization has been providing these products in the south of India through distributors and retails stores; both modern trade and local general stores.</p>

      <h2 className="upper_heading">What services and/or products do you offer? Primary & Allied</h2>
      <p className="upper_description">LRT Exports provides an export service to multiple countries in 3 trade classes. We will use henna as our primary product and initially introduce 3 packaging sizes(sku’s) to it. These sizes will resemble the primary requirement of said country, after research and analysis done we will make the decision. Allied products in the same trade class will include organic bath powders and organic face creams. Allied products from other trade classes will also include dental aligners which will be manufactured and exported by LRT Exports. Future allied products will also include motorcycle parts that are manufactured and exported by us.</p>

      <h2 className="upper_heading">Business Objectives</h2>
      <ul className="upper_description">
        <li><strong>Diverse Manufacturing and Commerce:</strong> The company is dedicated to the production, import, export, purchase, sale, and market preparation of a broad range of products, including but not limited to herbal organic products, electronic products, and medical/dental products, along with their respective by-products. Our objective encompasses comprehensive involvement in the manufacturing cycle, processing, and associated activities integral to these product categories.</li>
        <li><strong>Integrated Support and IT Services:</strong> We aim to establish and promote a suite of support services, both IT-enabled and conventional. This includes the development and maintenance of support centers, enterprise content management, data services, and the upkeep of information systems. Additionally, the company seeks to offer expert consultancy services in these domains, providing holistic and innovative solutions to businesses in need of sophisticated support infrastructure.</li>
        <li><strong>Specialization in Organic, Herbal, and Dental Products; Advanced Electronic Components:</strong> Our company is committed to specializing in the organic and herbal product sector, along with a focused approach in dental products and advanced electronic components. This specialization extends to the processing, importing, exporting, purchasing, selling, preparing, distributing, and trading of these products. In the realm of electronic components, we aim to provide cutting-edge solutions such as solar panel cleaning systems, innovative skincare machines, and state-of-the-art laser hair removal machines. We intend to leverage our expertise and market insight in these domains to establish a significant presence, focusing on quality, sustainability, and customer satisfaction in both health and environmental sectors.</li>
      </ul>

      <h2 className="upper_heading">Objective Category - Organic and Herbal product sale</h2>
      <p className="upper_description">Number of Customers: There is a varied customer base in multiple regions after an initial round of research. At this point, we have identified the South Asian and Middle Eastern area for operation. After an initial and basic understanding of the requirement, there is one example I recollect to emphasize my clarity in the export market for this product. The United States alone; a country not primarily known to be a user of henna products and by-products, consumes about 332,000 tons over a single year. Other countries such as the United Arab Emirates also have a high requirement of this product and all of its different forms as well.</p>

      <h2 className="upper_heading">Main Purpose to Start this Business</h2>
      <p className="upper_description">To be able to export a product we have a relatively good control over.</p>

      <h2 className="upper_heading">Has it been fulfilled?</h2>
      <p className="upper_description">In the process 15/02/2024</p>

      <h2 className="upper_heading">What was the plan for execution hitches that have been overcome?</h2>
      <p className="upper_description">WUT???</p>

      <h2 className="upper_heading">How many locations do you/plan to operate from?</h2>
      <p className="upper_description">Manufacturing and processing will be done from a single location and packaging will depend on the client's requirements. Our primary source of operation will be the website and all of our online presence.</p>

      <h2 className="upper_heading">List the risks in your business</h2>
      <p className="upper_description">Competitors that grow their own products and are able to maintain really high quality and a competitive price, an example of this would be Deepak industries.</p>

      <h2 className="upper_heading">Lay out the strategy</h2>
      <p className="upper_description">Our current strategy is to identify the requirements of two specific countries, attain the trade licenses and certificates required for that location. During this process, we have already analyzed the requirements for the henna leaf in a variety of substitutes. Our primary aim is to provide top quality along with the most competitive price and the best transportation timeframe.</p>

      <h2 className="upper_heading">Do you or your company have a special history, unique story, disruptive idea or awards, degrees, or industry professional designations?</h2>
      <p className="upper_description">Our current organization that is LRT Exports has recently been incorporated but our primary product that is Henna, gives the export organization a competitive advantage in the market we are planning to enter. This is possible only because the company that LRT Exports is tied up with Anoos Electrolysis and Obesity Pvt. Ltd. has been in the industry of manufacturing and processing henna and indigo powder for the past 40 years in India.</p>

      <h2 className="upper_heading">How did you position your business in terms of communication?</h2>
      <p className="upper_description">We provide a full view and description of the product and will always be available towards the customer. Transparency is key for us and we value the trust that our consumer, we clearly understand that this trust is formed only through growing the relationship with time. Our aim is to provide a seamless experience to the customers both on the online presence and physical stance through regular communication and being available at any time.</p>

      <h2 className="upper_heading">What are the words that can be used and not used?</h2>
      <p className="upper_description"><strong>Words to be used -</strong> Organic, Pure, Quality, Sourced authentically</p>
      <p className="upper_description"><strong>Not to be used -</strong> (None provided)</p>

      <h2 className="upper_heading">Are there any specific parameters like laws or guidelines you must work within? Name the restrictions.</h2>
      <p className="upper_description">Guidelines within which LRT Exports must operate include trade certification from the country of origin and a variety of licenses along with specific certifications required for the respective country into which the organization will be providing this product to. Each specific category has a specific certification or license that is required for this purpose.</p>

      <h2 className="upper_heading">What guarantee does your whole business depend on?</h2>
      <p className="upper_description">Our primary ability is to provide the best quality available along with the most competitive price in the market. We are able to achieve this through a relationship with Anoos Electrolysis and Obesity. We will be able to guarantee a timeline as well because of our longstanding relationship with the organization directly linked to the producer of the product.</p>
    </div>
  );
}

export default BusinessInfo;
