import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom' 
import Logo from '../../../Assets/LOGO(final).png'
import CustomLink from './CustomLink'
const Footer = () => {
  return (
    <div className="" style={{ display: 'block', background: '#333947', paddingBottom: '30px' }}>
      <div className='footer_main' style={{ display: 'flex', alignItems: 'flex-start', gap: '10%' }}>
        <div className="grid_item" style={{ marginRight: '5%' }} >
          <div className="nav_main_logo" style={{marginBottom:'40px'}}>
            {/* <img src={logo} alt="" /> */}
            <Link to={'/'} className="logo" style={{ fontSize: '40px', fontWeight: '700', color: 'white', textDecoration: 'none',marginBottom:'40px',height:'fit-content' }}><img src={Logo} alt=''></img></Link>
          </div>
        </div>
        <div className="footer_main_first" style={{margin:'0'}}>
          {/* <img src={footer_logo} alt="" /> */}

          <div className="grid_item">
            <p className="grid_item_title">MENU</p>
            <CustomLink to={'/what-we-do'} text={'WHAT WE DO'} />
            <CustomLink to={'/our-story'} text={'OUR STORY'} />
            {/* <CustomLink to={'/aboutus'} text={'ABOUT US'} /> */}
            <CustomLink to={'/partners'} text={'PARTNERS'} />
            <CustomLink to={'/contact'} text={'CONTACT'} />
          </div>
          <div className="grid_item">
            <p className="grid_item_title">LEGAL INFO</p>
            <CustomLink to={'/faq'} text={'FAQ'} />
            <CustomLink to={'/privacy'} text={'PRIVACY'} />
            <CustomLink to={'/terms'} text={'T&C'} />
          </div>
          <div className="grid_item">
            <p className="grid_item_title">SOCIAL MEDIA</p>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <FontAwesomeIcon style={{ color: 'white' }} icon={faInstagram} />
              <Link to={'https://www.instagram.com/lrt.exports/'} className="grid_items">INSTAGRAM</Link>
            </div>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <FontAwesomeIcon style={{ color: 'white' }} icon={faYoutube} />
              <Link to={'https://www.youtube.com/@LRTExports'} className="grid_items" style={{ paddingRight: '0px' ,textDecoration:'none'}}>YOUTUBE</Link>
            </div>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <FontAwesomeIcon style={{ color: 'white' }} icon={faLinkedin} />
              <Link to={'https://www.linkedin.com/in/lrt-exports-0a2ba7301/'} className="grid_items">LINKEDIN</Link>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright">© 2024 LRT EXPORTS PVT LTD., All rights reserved. </p>
    </div>
  )
}

export default Footer