import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const AboutBanner = () => {
    useEffect(() => {
        const handleMouseEnter = (classname = 'about_banner_content') => {
            gsap.to(`.${classname}`, {
                height: 'auto',
                autoAlpha: 1,
                duration: 2,
                ease: 'power2.inOut'
            });
        };

        handleMouseEnter();
    }, []);

    return (
        <div className='about_banner_main_div'>
            <div className="about_banner_content" style={{ height: 0, opacity: 0 }}>
                <p className="about_banner_heading">OUR VALUES</p>
                <p className="about_banner_text">CONSCIOUS I PIONEERS l INNOVATION l BORN IN INDIA</p>
            </div>
        </div>
    );
};

export default AboutBanner;
