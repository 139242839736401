import React, { useEffect } from 'react'
import './what.css'
import Banner from './smallCompo/Banner'
import WhatUpper from './smallCompo/WhatUpper'
import YoutubeEmbed from './smallCompo/YoutubeEmbed'
const WhatWeDo = () => {
  useEffect(() => {
    const scrollToNavDiv = async () => {
      const navDiv = document.getElementById('nav_main');
      if (navDiv) {
        await navDiv.scrollIntoView({ behavior: 'smooth' });
      }
    };
 
    scrollToNavDiv();
  }, []);
  return (
    <div className='whatwedo_main_component'>
      <Banner />
      <WhatUpper />
      <YoutubeEmbed /> 
    </div>
  )
}

export default WhatWeDo