import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const linkStyle = {
    textDecoration: 'none',
    position: 'relative',
    color: 'white',
    marginBottom: '5px',
    width: 'fit-content',
    marginTop: '5px',
};

const underlineStyle = {
    height: '2px',
    background: 'transparent',
    transition: 'width 1s', // Transition for width change over 3 seconds
};

const CustomLink = ({ to, text }) => {
    const [flag, setFlag] = useState(false);

    return (
        <Link className='links_pages' to={to} style={linkStyle} onMouseOver={() => setFlag(true)} onMouseLeave={() => setFlag(false)}>
            {text}
            <div style={{ ...underlineStyle, width: flag ? '100%' : '0',background:'white' }} className='underline_div' />
        </Link>
    ); 
};

export default CustomLink;
