import React, { useEffect } from 'react'
import './story.css'
import StoryBanner from './smallCompo/StoryBanner'
import StoryMain from './smallCompo/StoryMain'
const Story = () => {
  useEffect(() => {
    const scrollToNavDiv = async () => {
      const navDiv = document.getElementById('nav_main');
      if (navDiv) {
        await navDiv.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToNavDiv();
  }, []);
  return (
    <div className='story_main_component' >
      <StoryBanner />
      <StoryMain />
    </div>
  )
}

export default Story