import React, { useEffect, useState } from 'react';
import './faq.css'
const FAQItem = ({ question, answer }) => {
    const [pressed, setPressed] = useState(false);

    return (
        <article className="FAQItem_faq__Fbxhy FAQItem_is-dark__zv1Ig" style={{background:"white"}}>
            <a aria-pressed={pressed} onClick={() => setPressed(!pressed)} style={{ cursor: 'pointer' }} role="button" tabIndex="0" className="">
                <p style={{ color: 'black', fontSize: '24px', fontFamily: `'Larken Demo', sans-serif` }} className="heading-l">{question}</p>
                <div className="FAQItem_icon__CINpU" style={{ cursor: 'pointer' }}>
                    {pressed &&
                        <svg fill="none" height="24" viewBox="0 0 40 40" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 20H38" stroke="currentColor" strokeWidth="4" ></path>
                        </svg>}
                    {!pressed &&
                        <svg fill="none" height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg" >
                            <path clipRule="evenodd" d="M30 14.5H21.0909C19.1077 14.5 17.5 12.8923 17.5 10.9091V2H14.5V10.9091C14.5 14.5491 17.4509 17.5 21.0909 17.5H30V14.5ZM2 17.5H10.9091C12.8923 17.5 14.5 19.1077 14.5 21.0909V30H17.5V21.0909C17.5 17.4509 14.5491 14.5 10.9091 14.5H2V17.5Z" fill="currentColor" fillRule="evenodd"></path>
                        </svg>}
                </div>
            </a>
            {pressed && <div style={{ height: 'fit-content' }} className="">
                <div className="body-l">
                    <p><p style={{ fontWeight: '400', color: "black", fontSize: '18px', fontFamily: `'Times New Roman', Times, serif` }}>{answer}</p></p>
                </div>
            </div>}
        </article>
    );
};

const FAQSection = () => {

    useEffect(() => {
        const scrollToNavDiv = async () => {
            const navDiv = document.getElementById('nav_main');
            if (navDiv) {
                await navDiv.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNavDiv();
    }, []);

    return (
        <section style={{ minHeight: '1270px' }} className="SectionFAQ_faq__ERUdf SectionFAQ_is-dark__4l0Rv">
            <div data-grid="" data-container-size="l" data-cols="12" className="">
                <div data-col="" data-span="4" style={{ '--span': 4, '--start': 2 }}>
                    <h3 className="SectionFAQ_title___dzk8 heading-3-xl">FAQs</h3>
                    <p className="SectionFAQ_description__O1IHD body-1-xl">Because we believe in transparency right from the jump.</p>
                </div>
                <div data-col="" data-span="9" style={{ '--span': 9, '--start': 6 ,background:'white'}}>
                    <FAQItem
                        question="What products does LRT Exports specialize in?"
                        answer="LRT Exports specializes in organic henna and henna-based products, offering pure, high-quality options for hair care and coloring needs."
                    />
                    <FAQItem
                        question="How long has LRT Exports been in the business?"
                        answer="TLRT Exports, formerly known as Anoos Electrolysis and Obesity Pvt. Ltd., has been in the industry since 1982, with over 40 years of expertise in sourcing and delivering premium henna products.
                        "
                    />
                    <FAQItem
                        question="What sets LRT Exports apart from competitors?"
                        answer="LRT Exports distinguishes itself through a longstanding partnership with Anoos Electrolysis and Obesity Pvt. Ltd., ensuring unparalleled quality control and sourcing directly from trusted farmers. Our commitment to purity, reliability, and ethical practices is unmatched.                        "
                    />
                    <FAQItem
                        question="What countries does LRT Exports ship to?"
                        answer="LRT Exports offers international shipping to cater to customers worldwide, ensuring that our premium henna products are accessible globally."
                    />
                    <FAQItem
                        question="What’s the average length of a project?"
                        answer="Projects usually run between 4 to 12 weeks."
                    />
                    <FAQItem
                        question="Can LRT Exports accommodate custom packaging for private labeling?"
                        answer="Yes, LRT Exports provides customized packaging options, including private labeling, to enhance brand value and cater to the specific branding requirements of our customers."
                    />
                    <FAQItem
                        question="Does LRT Exports offer OEM and ODM services?"
                        answer="Absolutely, LRT Exports specializes in OEM (Original Equipment Manufacturer) and ODM (Original Design Manufacturer) services, allowing global brands to bring their unique visions to life with customized formulations, branding, and packaging."
                    />
                    <FAQItem
                        question="How does LRT Exports ensure the quality of its products?"
                        answer="Quality assurance is paramount at LRT Exports. We maintain strict quality control measures throughout the sourcing, manufacturing, and packaging processes, ensuring that only the purest and highest-quality henna products reach our customers."
                    />
                    <FAQItem
                        question="What audience does LRT Exports primarily cater to?"
                        answer=" LRT Exports primarily caters to women aged 40 and above who prioritize natural, organic hair care solutions for health and beauty benefits. Our products are tailored to meet their needs for purity, effectiveness, and reliability.
                        "
                    />
                    <FAQItem
                        question="How can customers trust LRT Exports' products?"
                        answer="Customers can trust LRT Exports' products due to our unwavering commitment to quality, reliability, and ethical business practices. Our longstanding presence in the industry and partnership with trusted suppliers ensure that customers receive only the best.
                        "
                    />
                    <FAQItem
                        question="What are the benefits of using LRT Exports' henna products?"
                        answer="Using LRT Exports' henna products offers numerous benefits, including natural hair coloring, enhanced hair health, and the assurance of chemical-free, organic ingredients. Our products provide effective results while prioritizing the well-being of our customers' hair."
                    />
                </div>
            </div>
        </section>
    );
};

export default FAQSection;
