import React, { useState } from 'react'
import logo from '../../../Assets/home/Recurso_15.png.png'
import cart from '../../../Assets/home/Vector (8).png'
import search from '../../../Assets/home/Vector (9).png'
import '../home.css'
import SearchBar from './SearchBar'
import { Link } from 'react-router-dom'
import Logo from '../../../Assets/LOGO(final).png'
import SideBar from './SideBar'
import CustomLink from './CustomLink'


const NavMain = () => {

  return (
    <div className='nav_main' id='nav_main'>
      <div className="nav_main_center">
        <div className='menu_button'>
          <SideBar />
        </div>
        <div className="nav_main_logo">
          {/* <img src={logo} alt="" /> */}
          <Link to={'/'} className="logo" style={{ fontSize: '40px', fontWeight: '700', color: 'white', textDecoration: 'none' }}><img src={Logo} alt=''></img></Link>
        </div>
        <div className="nav_links">
          <CustomLink to={'/what-we-do'} text={'WHAT WE DO'} />
          <CustomLink to={'/our-story'} text={'OUR STORY'} />
          {/* <CustomLink to={'/aboutus'} text={'ABOUT US'} /> */}
          <CustomLink to={'/partners'} text={'PARTNERS'} />
          <CustomLink to={'/contact'} text={'CONTACT'} />
        </div> 
        {/* <div className='nav_main_last' style={{ marginLeft: 'auto' }}>
          <SearchBar />
          <img className='cart' src={cart} alt="" />
        </div>  */}
      </div>
    </div>
  )
}

export default NavMain