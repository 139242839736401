import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, TextField } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import search from '../../../Assets/home/Vector (9).png'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';

const MainDiv = styled(Box)(({

}))

export default function TemporaryDrawer() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    }; 

    const DrawerList = (
        <Box sx={{ width: '600px', maxWidth: '95vw', background: '#D3D3D3', height: '100vh' }} role="presentation" >
            <List style={{ alignItems: 'center',gap:'5%' }}>
            <TextField id="outlined-basic" label="search" variant="outlined" style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '20px', width: '70%' }} />
            <FontAwesomeIcon icon={faXmark} onClick={()=>setOpen(false)} style={{cursor:'pointer'}}/>
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>

                    </ListItem>
                ))}
            </List>
        </Box>
    );
    return (
        <div>
            <img src={search} className='searchh' alt="" onClick={toggleDrawer('right', true)} />
            <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}