import React from 'react';
import { gsap } from 'gsap';

const WhatUpper = () => {
    const handleMouseEnter = () => {
        gsap.to('.what_process_img', {
            left: '15%', // Move the element to the left by its own width
            duration: 2,
            width: '70vw',
            backgroundSize: '70vw 100vh',
            ease: 'power2.inOut'
        });
    };

    return (
        <div className='what-upper' >
            <div className="what_upper_image" onMouseEnter={handleMouseEnter}>
                <p className="upper_description upper_description1" style={{textAlign:'start'}}>Our innovative Indian project cultivates and processes premium, organically grown henna, renowned for its scalp-cleansing and healing properties, partnering with meticulous growers in South India who utilize advanced technology for extraction. 
                    <br />
                    <br />
                    Our mission is to provide the highest quality henna powder for various applications, while prioritizing both ecological and socially responsible practices throughout the process.
                </p>
            </div>
        </div>
    );
};

export default WhatUpper;
