// TermsAndConditions.js

import React, { useEffect } from 'react';
import './privacy.css'
const TermsAndConditions = () => {
  useEffect(() => {
    const scrollToNavDiv = async () => {
      const navDiv = document.getElementById('nav_main');
      if (navDiv) {
        await navDiv.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToNavDiv();
  }, []);
  return (
    <div className="terms-and-conditions">
      <h1 className="terms-and-conditions-title">Terms and Conditions</h1>
      <div className="terms-and-conditions-content">
        <p>
          Welcome to LRT Exports, a henna-based export company based in Hyderabad, Telangana, India. These Terms and Conditions govern your use of our services, including browsing our website, purchasing products, and engaging in any business transactions with us. By accessing or using our services, you agree to comply with these Terms and Conditions.
        </p>
        <h2>Product information</h2>
        <p>
          We specialize in the export of henna-based products, including but not limited to henna powder, henna cones, and henna-based cosmetics. All product descriptions and specifications provided on our website are for informational purposes only. We make every effort to ensure the accuracy of the information; however, we do not guarantee that the information is always up to date, complete, or error-free.
        </p>

        <h2>Orders and Payments</h2>
        <p>
          By placing an order with us, you agree to provide accurate and complete information. All orders are subject to availability and acceptance by LRT Exports. We reserve the right to refuse or cancel any order for any reason, including but not limited to product unavailability, inaccuracies in pricing or product information, or suspicion of fraudulent activity.
          <br /><br />
          Payments for orders are processed securely through our chosen payment gateway. We accept various forms of payment, including credit/debit cards, bank transfers, and other electronic payment methods. All prices are listed in Indian Rupees (INR) unless otherwise stated and are exclusive of applicable taxes and shipping fees.
        </p>

        <h2>Shipping and Delivery</h2>
        <p>
          We offer shipping services to destinations worldwide. Shipping costs and delivery times may vary depending on the destination and the shipping method selected. We aim to process and dispatch orders promptly; however, we cannot guarantee specific delivery dates.
          <br /><br />Customers are responsible for providing accurate shipping information. LRT Exports is not liable for any delays, damages, or losses resulting from inaccurate or incomplete shipping information provided by the customer.
        </p>

        <h2>Returns and Refunds</h2>
        <p>
          We take pride in the quality of our products and strive for customer satisfaction. If you are not satisfied with your purchase for any reason, please contact us within [number] days of receiving your order to arrange for a return or exchange.
          <br /><br />
          Returned products must be unused, unopened, and in their original packaging to be eligible for a refund or exchange. Customers are responsible for return shipping costs unless the return is due to an error on our part or a defective product.
          <br /><br />
          Refunds will be issued within [number] business days of receiving and inspecting the returned products. Refunds will be processed using the original payment method.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          All content on our website, including but not limited to text, images, logos, and trademarks, is the property of LRT Exports or its licensors and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written consent.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, LRT Exports shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of our services or products, including but not limited to lost profits, lost revenue, loss of data, or business interruption.
        </p>

        <h2>Governing Law and Dispute Resolution</h2>
        <p>
          These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any dispute arising out of or in connection with these Terms and Conditions shall be resolved amicably through negotiations between the parties. If the dispute cannot be resolved through negotiations, it shall be submitted to the exclusive jurisdiction of the courts in Hyderabad, Telangana, India.
        </p>

        <h2>Amendments</h2>
        <p>
          LRT Exports reserves the right to update or modify these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. Your continued use of our services after the posting of any changes constitutes your acceptance of such changes.
        </p>

        <h2>Contact Us</h2>
        <p>
          if you have any questions or concerns about these Terms and Conditions, please contact us at [email address] or [phone number].
        </p>
        <p>You must not:</p>
        <ul>
          <li>1.Republish material from lrtexports</li>
          <li>2.Sell, rent or sub-license material from lrtexports</li>
          <li>3.Reproduce, duplicate or copy material from lrtexports</li>
          <li>4.Redistribute content from lrtexports</li>
        </ul>
        <p>This Agreement shall begin on the date hereof.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
