import React, { useEffect } from 'react';

const IdealCustomer = () => {
    useEffect(() => {
        const scrollToNavDiv = async () => {
            const navDiv = document.getElementById('nav_main');
            if (navDiv) {
                await navDiv.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNavDiv();
    }, []);
    return (
        <div style={{ background: '#EEECE6', padding: "30px 15%" }}>
            <h2 className="upper_heading" style={{ fontSize: '36px', fontWeight: '500' }}>Ideal Customer</h2>

            <h3 className="upper_heading">Customer Clusters</h3>
            <p className="upper_description">As a B2B organization, we target customer clusters within our base price point range, categorized by country, purchasing power, and other constraints.</p>

            <h3 className="upper_heading">Targeting Methods</h3>
            <p className="upper_description">We target these clusters by understanding specific distribution channels catering to our price range and constraints.</p>

            <h3 className="upper_heading">Segments Not Targeted</h3>
            <p className="upper_description">Segments not targeted include those falling outside our defined purchasing and distribution categories for the product.</p>
            <ul className="upper_description">
                <li>Cosmetics</li>
                <li>Healthcare products</li>
                <li>Hair care products</li>
                <li>Organic products</li>
                <li>Organic hair care products</li>
                <li>Organic hair coloring products</li>
                <li>Hair color</li>
            </ul>

            <h3 className="upper_heading">Customer Demographics and Psychographics</h3>
            <p className="upper_description">Primary audience: Women aged 40 and above.</p>

            <h3 className="upper_heading">Primary Reason for Buying</h3>
            <p className="upper_description">Customers purchase our product for its natural and organic process, providing desired hair color without chemicals and improving hair health.</p>

            <h3 className="upper_heading">Reasons for Trust</h3>
            <p className="upper_description">Trust is established through product quality, deliverability, constant support, and ethical responsibility by LRT Exports.</p>

            <h3 className="upper_heading">Reasons for Preference over Competition</h3>
            <p className="upper_description">Customers prefer our offering due to the unique sourcing relationship with an organization in India with 40 years of experience in providing high-quality products.</p>

            <h3 className="upper_heading">Marketing Strategy Approach</h3>
            <p className="upper_description">Our marketing strategies start with understanding our product's advantageous aspects.</p>
        </div>
    );
}

export default IdealCustomer;
