import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";

const Banner2 = () => {
    const textRef = useRef(null);
    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('sliding_element_right');
                    gsap.to(textRef.current, { opacity: 1, duration: 2 }); // Animate opacity to 1
                } else {
                    entry.target.classList.remove('sliding_element_right');
                    gsap.to(textRef.current, { opacity: 0, duration: 1 }); // Animate opacity to 0
                }
            });
        });
        observer.observe(targetRef.current);
        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, []);

    return (
        <div className='home_banner_two'>
            <div className="background_tint" style={{ justifyContent: 'flex-start', paddingLeft: '10%' }}>
                <div className="home_banner_two_text" ref={targetRef}>
                    <p style={{ width: '80%', opacity: "0", color: 'white', fontSize: '24px' }} ref={textRef}>
                        With over 35 years of experience, LRT Exports provides the highest grade of organic henna powder
                        <br />
                        <br />
                        Our mission is to get this prime quality henna products to customers globally - Enabling them to discover the vast array of uses of this ecologically and bodily, beneficial plant.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Banner2;
