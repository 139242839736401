import React, { useEffect, useState } from 'react';
import man from '../../Assets/man.webp';
import useMousePosition from '../hooks/useMousePosition';
import styles from './page.module.scss';
import { motion } from 'framer-motion';
import SendButton from './SendButton';
import toast from 'react-hot-toast';

const Contact1 = () => {
    const [details, setDetails] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const { x, y } = useMousePosition();
    const [isHovered, setIsHovered] = useState(false);
    const size = isHovered ? 400 : 40;

    useEffect(() => {
        const scrollToNavDiv = async () => {
            const navDiv = document.getElementById('nav_main');
            if (navDiv) {
                await navDiv.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNavDiv();
    }, []);

    const handleSubmit = async () => {
        try {
            console.log(process.env.MAIL_URL);
            const response = await fetch('https://lrtexports.com/API/mail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            });
            const data = await response.json();
            console.log(data);
            if(response.ok){
                toast.success('Thankyou for showing interest in our services. our team will contact you soon.')
                setDetails({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                });
            }
            else{
                toast.error('Error sending messages')
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    return (
        <div className='contact_1_main' style={{ background: 'white' }}>
            <h1 className='contact_1_main_heading' style={{ fontFamily: `'Larken Demo', sans-serif` }}>Let's chat with us</h1>
            <motion.div
                className={styles.mask}
                animate={{
                    WebkitMaskPosition: `${x - (size / 2)}px ${y - (size / 2)}px`,
                    WebkitMaskSize: `${size}px`,
                }}
                transition={{ type: "tween", ease: "backOut", duration: 0.5 }}
                style={{ display: 'flex', gap: '5%', height: '100%' }}>
                <div className="form_div_c1" style={{ width: '80%', display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <div style={{ display: 'flex', gap: '4%', width: '100%' }} className='first_inputs'>
                        <div style={{ width: "48%", display: 'flex', flexDirection: "column", gap: "10px", marginTop: '20px' }}>
                            <label htmlFor="name" style={{ fontFamily: `'Larken Demo', sans-serif` }}>Your Name:</label>
                            <input
                                id="name"
                                name="name"
                                value={details.name}
                                onChange={handleInputChange}
                                placeholder='Your name'
                                className='half_input'
                                type="text"
                                style={{ width: '100%', fontFamily: `'Larken Demo', sans-serif` }}
                            />
                        </div>
                        <div style={{ width: "48%", display: 'flex', flexDirection: "column", gap: "10px", marginTop: '20px' }}>
                            <label htmlFor="phone" style={{ fontFamily: `'Larken Demo', sans-serif` }}>Mobile:</label>
                            <input
                                id="phone"
                                name="phone"
                                value={details.phone}
                                onChange={handleInputChange}
                                placeholder='Your mobile'
                                className='half_input'
                                type="text"
                                style={{ width: '100%', fontFamily: `'Larken Demo', sans-serif` }}
                            />
                        </div>
                    </div>
                    <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "10px", marginTop: '20px' }}>
                        <label htmlFor="email" style={{ fontFamily: `'Larken Demo', sans-serif`, marginBottom: '0' }}>Your email:</label>
                        <input
                            id="email"
                            name="email"
                            value={details.email}
                            onChange={handleInputChange}
                            placeholder='Your email address'
                            type="text"
                            style={{ fontFamily: `'Larken Demo', sans-serif` }}
                        />
                    </div>
                    <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "10px", marginTop: '20px' }}>
                        <label htmlFor="message" style={{ fontFamily: `'Larken Demo', sans-serif`, marginBottom: '0' }}>Tell us more about your project goals:</label>
                        <textarea
                            id="message"
                            name="message"
                            value={details.message}
                            onChange={handleInputChange}
                            placeholder='Tell us more about yourself'
                            style={{ height: '160px', fontFamily: `'Larken Demo', sans-serif` }}
                            type="text"
                        />
                    </div>
                    <div style={{ marginTop: '40px', marginLeft: '20px' }}>
                        <SendButton func={handleSubmit} />
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default Contact1;
