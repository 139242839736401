import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import { styled } from "@mui/material";
import { carouselData, carouselImages } from '../../../Constants/data';

const Image = styled("img")(({ theme }) => ({
    // Your styles here
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const YoutubeEmbed = () => {
    const [ind, setInd] = useState(0);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        // Set the index when the component mounts
        setInd(0);
    }, []);

    const title_data = [
        '1. CROP/ SELECTION', '2. CLEANING & POWDERING', '3. SIFTING', '4. PACKAGING & DELIVERY'
    ]

    const handleSlideChange = (index) => {
        // Update the index when the slide changes
        console.log(index)
        if (index === 6) setFlag(false)
        else setFlag(true)
        setInd(index);
    };
 
    return (
        <div className='youtube_main_component'>
            <div className="carousel_wrapper">
                <p className="title" style={{ fontSize: '24px', marginLeft: '20px', marginBottom: '10px',color:"black" }}>{flag ? title_data[(ind - 2)] : title_data[0]}</p>
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    className='youtube_emb_carousel'
                    swipeable={true}
                    arrows={true}
                    dotListClass="dot_list"
                    draggable={true}
                    autoPlay={true}
                    showDots={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    slidesToSlide={1}
                    beforeChange={(nextSlide) => handleSlideChange(nextSlide)}
                >
                    {carouselImages.map((image, index) => (
                        <div key={index} style={{ width: '100%', height: '100%', backgroundSize: '100% 100%', objectFit:'cover',backgroundImage: `url('${image}')` }}>
                            {/* <Image src={image} alt="Banner" /> */}
                            <div className="background_tint" style={{ display: 'flex', alignItems: 'flex-end',justifyContent:'center' }}>
                                <p className='carousel_inner_p' style={{ color: "white", fontSize: '20px', fontWeight: '600', marginBottom: '30px',fontFamily: `'Vollkorn', serif`,textAlign:'center' }}>{carouselData[index]}</p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <p className="upper_description upper_description1" style={{ textAlign: 'start', marginTop: "50px", fontFamily: `'Vollkorn', serif`, fontWeight: '400' }}>Our international shipping announcement underscores our commitment to delivering premium products worldwide,  enhancing brand value and customer engagement.
                <br/>
                {/* <br /> */}
                Offering tailored OEM and ODM services, we empower global brands to innovate and excel in the market, from formulation to branding.
            </p>
            <p className="upper_description headding" style={{ textAlign: 'center', marginTop: "35px", fontFamily: `'Larken Demo', sans-serif`, fontWeight: '500', fontSize: '48px',lineHeight:'normal' ,color:"black"}}>
                No Order Too Small or Big
            </p>
            <p className="upper_description upper_description1" style={{ textAlign: 'start', marginTop: "20px", fontFamily: `'Vollkorn', serif`, fontWeight: '400', marginBottom: '50px' }}>
                Whether retail or wholesale, we cater to all quantities, ensuring flexibility and accessibility for all customers.
            </p>
        </div>
    );
};

export default YoutubeEmbed;
