import React, { useEffect } from 'react'
import './home.css'
import Banner from './smallCompo/Banner'
import Banner2 from './smallCompo/Banner2'
import Banner3 from './smallCompo/Banner3'
import News from './smallCompo/News'
const Home = () => {
    useEffect(() => {
        const scrollToNavDiv = async () => {
            const navDiv = document.getElementById('nav_middle_main');
            if (navDiv) {
                await navDiv.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNavDiv();
    }, []);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', background: 'white' }}>
            <Banner />
            <Banner2 />
            <Banner3 />
            <div className="home_text_div">
                <p className="home_text" style={{ color: "black" }}>
                    "Using sustainable methods  of processing our organic henna powder, we deliver this authentic product to you"
                </p>
            </div> 
            <News />
        </div>
    )
}

export default Home