import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { lightTheme, darkTheme } from "./themes.js";
import Cursor from './Components/gsap/Cursor';
import Home from './Components/Home/Home';
import NavMiddle from './Components/Home/smallCompo/NavMiddle';
import NavMain from './Components/Home/smallCompo/NavMain';
import About from './Components/About/About';
import Contact1 from './Components/Contact/Contact1.jsx';
import Story from './Components/Story/Story';
import WhatWeDo from './Components/What/WhatWeDo';
import BusinessInfo from './Components/AboutDetails/BusinessDetails.jsx';
import IdealCustomer from './Components/AboutDetails/IdealCustomer.jsx';
import AboutMarket from './Components/AboutDetails/AboutMarket.jsx';
import Footer2 from './Components/Footer/Footer2.jsx';
import Fame from './Components/Footer/Fame.jsx';
import FAQSection from './Components/FAQ/FAQ.jsx';
import PrivacyPolicy from './Components/FAQ/PrivacyPolicy.jsx';
import TermsAndConditions from './Components/FAQ/Terms.jsx';
import Footer from './Components/Home/smallCompo/Footer.jsx';
import Partners from './Components/partners/Partners.jsx';

function App() {
  const [theme, setTheme] = useState(lightTheme);

  const toggleTheme = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  return (
    <div style={{ overflow: 'hidden',background:"white" }}>
      <BrowserRouter>
        {/* <NavMiddle /> */}
        <NavMain />
        <Routes>
          <Route path="/" element={
            <>
              <Cursor theme={theme} />
              <Home />
            </>
          } />
          <Route path='/aboutus' element={
            <>
              <Cursor theme={theme} />
              <About />
            </>
          } />
          <Route path='/contact' element={
            <>
              <Cursor theme={theme} />
              <Contact1 />
            </>
          } />
          <Route path='/our-story' element={
            <>
              <Cursor theme={theme} />
              <Story />
            </>
          } />
          <Route path='/what-we-do' element={
            <>
              <Cursor theme={theme} />
              <WhatWeDo />
            </>
          } />
          <Route path='/about-your-business' element={
            <>
              <Cursor theme={theme} />
              <BusinessInfo />
            </>
          } />
          <Route path='/ideal-customer' element={
            <>
              <Cursor theme={theme} />
              <IdealCustomer />
            </>
          } />
          <Route path='/about-your-market' element={
            <>
              <Cursor theme={theme} />
              <AboutMarket />
            </>
          } />
          <Route path='/fame' element={
            <>
              <Cursor theme={theme} />
              <Fame />
            </>
          } />
          <Route path='/faq' element={
            <>
              <Cursor theme={theme} />
              <FAQSection />
            </>
          } />
          <Route path='/privacy' element={
            <>
              <Cursor theme={theme} />
              <PrivacyPolicy />
            </>
          } />
          <Route path='/terms' element={
            <>
              <Cursor theme={theme} />
              <TermsAndConditions />
            </>
          } />
          <Route path='/partners' element={
            <>
              <Cursor theme={theme} />
              <Partners />
            </>
          } />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
