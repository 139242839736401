import React, { useEffect } from 'react'
import { gsap } from 'gsap'
const Banner = () => {
  useEffect(() => {
    const handleMouseEnter = (classname = 'what_banner_inner_div') => {
      gsap.to(`.${classname}`, {
        height: 'auto',
        autoAlpha: 1,
        duration: 2,
        ease: 'power2.inOut'
      });
    };

    handleMouseEnter();
  }, []);
  return (
    <div className='what_banner_main'>
      <div className="background_tint">
        <div className="what_banner_inner_div" style={{ height: 0, opacity: 0 }}>
          <p className="what_banner_heading">WHAT WE DO</p>
          {/* <p className="what_banner_desc">CROP & EXTRACTION METHOD</p> */}
        </div>
      </div>
    </div>
  )
}

export default Banner