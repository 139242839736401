import React, { useEffect } from 'react'
import './about.css'
import AboutBanner from './smallCompo/AboutBanner'
import AboutMain from './smallCompo/AboutMain'
const About = () => {
  useEffect(() => {
    const scrollToNavDiv = async () => {
      const navDiv = document.getElementById('nav_main');
      if (navDiv) {
        await navDiv.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToNavDiv();
  }, []);
  return (
    <div style={{ background: '#40462C' }}>
      <AboutBanner />
      <AboutMain />
    </div>
  )
}

export default About 