import React, { useState } from 'react';
import './contact.css';

const SendButton = ({func}) => {
    const [hoverDirection, setHoverDirection] = useState(null);

    const handleMouseEnter = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const width = e.target.offsetWidth;
        const height = e.target.offsetHeight;

        const xRatio = x / width;
        const yRatio = y / height;

        if (xRatio <= 0.5 && yRatio <= 0.5) {
            setHoverDirection('top-left');
        } else if (xRatio > 0.5 && yRatio <= 0.5) {
            setHoverDirection('top-right');
        } else if (xRatio <= 0.5 && yRatio > 0.5) {
            setHoverDirection('bottom-left');
        } else {
            setHoverDirection('bottom-right');
        }
    };

    const handleMouseLeave = () => {
        setHoverDirection(null);
    };

    return (
        <button
            aria-label="Send"
            onClick={func}
            type="submit"
            className={`send_button Button_l__6vnrN Button_primary__3_h20 cursor-element ${hoverDirection}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ background: "rgb(64, 70, 44)" }}
        >
            <p className="" style={{ color: '#CEA165', fontWeight: '600' }}>Send</p>
            <svg fill="none" height="24" style={{ transform: 'rotate(-90deg)',color:'#CEA165' }} viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path clipRule="evenodd" d="M15.5355 18.8787L20.4853 13.9289L19.0711 12.5147L14.1213 17.4645C13.7938 17.792 13.4094 18.0279 13 18.1723L13 3L11 3L11 18.1723C10.5905 18.0279 10.2062 17.792 9.87867 17.4645L4.92892 12.5147L3.51471 13.929L8.46445 18.8787C10.4171 20.8313 13.5829 20.8313 15.5355 18.8787Z" fill="currentColor" fillRule="evenodd" />
            </svg>
        </button>
    );
};

export default SendButton;
