import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import banner_video from '../../../Assets/video/banner_video.mp4'
const Banner = () => {
    const [flag, setFlag] = useState(false)
    useEffect(() => {
        const handleMouseEnter = (classname = 'home_banner_text') => {
            gsap.to(`.${classname}`, {
                left: "-25%", // Move the element to the left by its own width
                duration: 2,
                ease: 'power2.inOut'
            });
        };

        handleMouseEnter();
    }, []);
    return (
        <div className='' style={{ height: '650px', width: '100vw', position: 'relative', overflow: 'hidden' }}>
            
                <video style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', objectFit: 'cover' }} muted={!flag} onClick={() => setFlag(!flag)} loop autoPlay>
                    <source src={banner_video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="background_tint2" style={{position:'absolute'}}>
                <p className="home_banner_text" style={{ right: '50%', top: '50%', transform: 'translate(50%, -50%)', position: 'absolute', color: '#C99652' }}>
                    Discover the purity of nature's finest henna.
                </p>
            </div>
        </div>

    )
}

export default Banner