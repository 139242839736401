// PrivacyPolicy.js

import React, { useEffect } from 'react';
import './privacy.css'
const PrivacyPolicy = () => {
  useEffect(() => {
    const scrollToNavDiv = async () => {
      const navDiv = document.getElementById('nav_main');
      console.log(navDiv)
      if (navDiv) {
        await navDiv.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToNavDiv();
  }, []);
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <div className="privacy-policy-content">
        <p>
          At LRT Exports Pvt. Ltd. (referred to as "LRT Exports," "we," "us," or "our"), we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when you use our website and our services.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We collect various types of information, including:
          <ul>
            <li>1. Personal Information: This may include your name, contact details, email address, and other identifiers necessary for communication and order processing.</li>
            <li>2. Payment Information: When you make purchases on our website, we collect payment details such as credit card information or other payment methods.</li>
            <li>3. Usage Information: We gather data about your interactions with our website, including browsing history, IP address, and device information.
            </li>
            <li>This includes any communications you send to us, such as emails or messages through our website.
            </li>
          </ul>
        </p>
        <h2>How We Use the Information</h2>
        <p>
          We use the information we collect for the following purposes:
          <ul>
            <li>1.Internal record keeping</li>
            <li>2.We may use the information to improve our products and services</li>
            <li>3.We may periodically send promotional emails about new products, special offers, or other information which
              we think you may find interesting using the email address which you have provided</li>
            <li>4.From time to time, we may also use your information to contact you for market research purposes. We may
              contact you by email, phone, fax or mail. We may use the information to customize the website according to
              your interests</li>
          </ul>
        </p>
        <h2>Security</h2>
        <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
          disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and
          secure the information we collect online.</p>
        <h2>Links to Other Websites</h2>
        <p>Our website may contain links to other websites of interest. However, once you have used these links to leave
          our site, you should note that we do not have any control over that other website. Therefore, we cannot be
          responsible for the protection and privacy of any information which you provide whilst visiting such sites and
          such sites are not governed by this privacy statement. You should exercise caution and look at the privacy
          statement applicable to the website in question.</p>
        <h2>Controlling Your Personal Information</h2>
        <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
        <ul>
          <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate
            that you do not want the information to be used by anybody for direct marketing purposes</li>
          <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may
            change your mind at any time by writing to or emailing us at [email address]</li>
        </ul>
        <p>We will not sell, distribute, or lease your personal information to third parties unless we have your
          permission or are required by law to do so. We may use your personal information to send you promotional
          information about third parties which we think you may find interesting if you tell us that you wish this to
          happen.</p>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at [contact email address].</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
